import React from "react";
import { NavLink } from "react-router-dom";
import { SocialIcon } from "react-social-icons";

const NavBar = () => {
  return (
    <div>
      {/* <header style={{ backgroundColor: "rgb(17 94 89)" }}> */}
      <header className="bg-teal-800">
        <div className="container mx-auto flex justify-between">
          <nav className="flex">
            <NavLink
              to="/"
              exact
              // activeClassName="text-white"
              className="inline-flex items-center py-6 px-3 mr-4 text-teal-100 hover:text-teal-400 text-4xl font-bold cursive tracking-widest"
            >
              {" "}
              Home{" "}
            </NavLink>
            <NavLink
              to="about"
              className="inline-flex items-center py-3 px-3 my-6 rounded text-teal-200 hover:text-teal-400"
              // activeClassName="text-red-100 bg-red-700"
            >
              {" "}
              About{" "}
            </NavLink>
            <NavLink
              to="post"
              className="inline-flex items-center py-3 px-3 my-6 rounded text-teal-200 hover:text-teal-400"
              // activeClassName="text-red-100 bg-red-700"
            >
              {" "}
              Blog Posts{" "}
            </NavLink>
            <NavLink
              to="project"
              className="inline-flex items-center py-3 px-3 my-6 rounded text-teal-200 hover:text-teal-400"
              // activeClassName="text-red-100 bg-red-700"
            >
              {" "}
              Projects{" "}
            </NavLink>
          </nav>
          <div className="inline-flex py-3 px-3 my-6">
            <SocialIcon
              url="https://www.linkedin.com/in/courtney-wilson22/"
              className="mr-4"
              target="_blank"
              fgColor="#fff"
              style={{ height: 35, width: 35 }}
            />
            <SocialIcon
              url="https://www.instagram.com/cmwilson21/"
              className="mr-4"
              target="_blank"
              fgColor="#fff"
              style={{ height: 35, width: 35 }}
            />
            <SocialIcon
              url="https://github.com/cmwilson21"
              className="mr-4"
              target="_blank"
              fgColor="#fff"
              style={{ height: 35, width: 35 }}
            />
          </div>
        </div>
      </header>
    </div>
  );
};

export default NavBar;
